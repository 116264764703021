import * as React from 'react';
import { SelectOptions } from '../../interfaces/CoreInterfaces';
import DataEntryModal, { DataEntryModalField, DataEntryModalFieldSelect, DataEntryModalFieldStatic, DataEntryModalFieldText } from '../CoreComponents/DataEntryModal';
import { pullProperties } from '../../functions/fetchLinkedObjects';

interface CombinePropertiesModalState {
  properties: Array<SelectOptions>
  propertyOne: SelectOptions;
  propertyTwo: SelectOptions;
  newCode: string;
  newName: string;
}
export default class CombinePropertiesModal extends React.Component<{}, CombinePropertiesModalState> {

  entryModal: React.RefObject<DataEntryModal> = React.createRef<DataEntryModal>();

  constructor(props: {}) {
    super(props);
    this.state = {
      properties: [],
      propertyOne: { label: "Select First Property", value: "" },
      propertyTwo: { label: "Select Second Property", value: "" },
      newCode: "",
      newName: ""
    }
  }

  async componentDidMount(): Promise<void> {
    let properties = await pullProperties();
    this.setState({ properties: properties });
  }

  show(): void {
    this.generateData();
  } 

  generateData() {
    let data: Array<DataEntryModalField> = [];
    data.push({ key: "NOTE", name: "note", value: "Please make sure Property 1 has the correct address.", static: true} as DataEntryModalFieldStatic)
    data.push({ key: "propertyOne", name: "Property 1", patchUrl: null, value: this.state.propertyOne, options: this.state.properties, isMulti: false } as DataEntryModalFieldSelect)
    data.push({ key: "propertyTwo", name: "Property 2", patchUrl: null, value: this.state.propertyTwo, options: this.state.properties, isMulti: false} as DataEntryModalFieldSelect)
    data.push({ key: "newName", name: "New Name", patchUrl: null, value: this.state.newName, type: "text" } as DataEntryModalFieldText);
    data.push({ key: "newCode", name: "New Code", patchUrl: null, value: this.state.newCode, type: "text" } as DataEntryModalFieldText);
    this.entryModal.current.update(data);
    this.entryModal.current.show("Combine Properties", "", "./api/property/combine-properties")
  }

  render(): JSX.Element {
    return (
      <DataEntryModal ref={this.entryModal} callback={() => window.location.reload()} />
    )
  }
} 