import * as React from 'react';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';
import { Col, Row } from 'reactstrap';
import axios from 'axios';
import { WarningModal } from '../../CoreComponents/Modals';


export default class TechManagerTools extends React.Component<{}, {}> {

  warningModal: React.RefObject<WarningModal> = React.createRef<WarningModal>();

  constructor(props: {}) {
    super(props);
    this.state = {
    }
    this.sendHandbook = this.sendHandbook.bind(this);
  }

  async componentDidMount(): Promise<void> {
    
  }

  async sendHandbook(): Promise<void> {
    var response = await axios.get('./api/manual/send-employee-handbook');
    this.warningModal.current.show(response.data, "Response Data");
  }

  render(): JSX.Element {
    return (
      <>
        <WarningModal ref={this.warningModal} />
        {RenderHeader("Employee Tools")}
        <div style={{ height: "25vh" }}>
          <Row>
            <Col>
              <h5>Send Handbook to Staff</h5>
            </Col>
            <Col>
              <input type="button" value="Send" className="standard-input" onClick={() => this.sendHandbook()} />
            </Col>
          </Row>
        </div>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
      </>
    )
  }
}
