import * as React from 'react';
import * as Structs from './Structs';
import axios from 'axios';
import { CreateGlobalAlert } from '../../functions/CreateGlobalAlerts';
import { reactSelectBasicStyle } from '../../style/select-constants';
import Select from 'react-select';
import { YesNoModal } from '../CoreComponents/Modals';
import { RelatedPortfolios, RelatedPortfolios_FilterSelect } from '../../constants/EnumConstants';
import { SelectOptions } from '../../interfaces/CoreInterfaces';
import { pullViridianMaintenanceManagers, pullViridianRegionals, pullViridianSiteManagers } from '../../functions/fetchLinkedObjects';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { FilterUsedOptions, RemoveStickyOverlays, RestoreStickyOverlays } from '../../functions/selectTools';
import { Jobs, getBearerToken, getUserID, getUserJobFromServer } from '../../functions/authActions';
import { FilterAndSettingField } from '../CoreComponents/interfaces';
import FilteredWithSettingsTableHeader from '../CoreComponents/CoreTableHeaders';
import { GetSettings, SetSettings } from '../../functions/StateManagement/StateStorageFunctions';
import PropertyInformationEditModal from './PropertyModals/PropertyInformationEditModal';
import CombinePropertiesModal from './CombinePropertiesModal';

interface PropertyFilterSettings {
  propertyName: string;
  address: string;
  portfolio: SelectOptions;
  regionalList: Array<SelectOptions>;
}

export class PropertyRow extends React.Component<Structs.PropertyRowProps, Structs.PropertyRowState> {

  modalRef = React.createRef<YesNoModal>();

  constructor(props: Structs.PropertyRowProps) {
    super(props);
    this.state = {
      color: "#d7c7a7",
      backgroundColor: "#4C4A42"
    }
    this.archive = this.archive.bind(this);
    this.checkRedirect = this.checkRedirect.bind(this);
  }

  async archive(event: React.MouseEvent, name: string, id: string, callback: (event: SelectOptions) => void): Promise<void> {
    event.preventDefault();
    await axios.put('./api/property/' + id + "/archive").then(function (response) {
      CreateGlobalAlert(`Property ${name} has been archived`, 2000, callback);
    });
  }

  checkRedirect(): void {
    this.modalRef.current?.show(
      "Open in New Tab or Current Tab",
      "Open New Tab?",
      () => window.location.assign('./property-detail-page?uid=' + this.props.data.uid),
      () => window.open('./property-detail-page?uid=' + this.props.data.uid),
      "New Tab",
      "Current Tab"
    )
  }

  render(): JSX.Element {
    return (
      <tr>
        <YesNoModal ref={this.modalRef} />
        <td className="fixedTableCellFirst" style={{  color: this.state.color }}
          onMouseEnter={() => this.setState({ color: '#b89961' })} onMouseLeave={() => this.setState({ color: '#d7c7a7' })} >
          <em onClick={this.checkRedirect} style={{ cursor: "pointer" }}>
            {this.props.data.name}
          </em>
        </td>
        <td className="fixedTableCell">{this.props.data.address}</td>
        <td className="fixedTableCell">{this.props.data.portfolio}</td>
        <td className="fixedTableCell">{this.props.data.unitCount}</td>
        <td className="fixedTableCell">{this.props.data.regionalManager}</td>
        <td className="fixedTableCell">{this.props.data.siteManager}</td>
        <td className="fixedTableCell">{this.props.data.maintenanceManager}</td>
        <td className="fixedTableCell">
          <input type="button" className="standardInput" value="Archive"
            onClick={(e: React.MouseEvent) => this.archive(e, this.props.data.name, this.props.data.uid, this.props.callback)}
          />
        </td>
      </tr>
    )
  }
}

export class PropertySummaryTable extends React.Component<Structs.PropertySummaryTableProps, Structs.PropertySummaryTableState> {

  static displayName = PropertySummaryTable.name;
  personEditRef = React.createRef<PersonEditModal>();
  propertyEditModal: React.RefObject<PropertyInformationEditModal> = React.createRef<PropertyInformationEditModal>();
  propertyCombineModal: React.RefObject<CombinePropertiesModal> = React.createRef<CombinePropertiesModal>();

  settings: string = "propertyFilter";

  constructor(props: Structs.PropertySummaryTableProps) {
    super(props);
    let settings: PropertyFilterSettings = GetSettings<PropertyFilterSettings>(this.settings);
    if (settings === null) {
      settings = {
        propertyName: "", address: "", portfolio: { label: "All", value: "-1" }, regionalList: []
      }
    }
    this.state = {
      properties: [],
      originalList: [],
      pages: [],
      pageCount: { label: '25', value: '25' },
      page: { label: '1', value: '0' },
      propertyName: settings.propertyName,
      address: settings.address,
      portfolio: settings.portfolio ?? { label: "All", value: "-1" },
      headers: [],
      regionalList: settings.regionalList ?? [],
      regionals: []
    }
    this.changePropertyName = this.changePropertyName.bind(this);
    this.changeAddress = this.changeAddress.bind(this);
    this.selectPageCount = this.selectPageCount.bind(this);
    this.changePortfolio = this.changePortfolio.bind(this);
    this.changeRegional = this.changeRegional.bind(this);
    this.updatePage = this.updatePage.bind(this);
    this.selectPage = this.selectPage.bind(this);
    this.personEdit = this.personEdit.bind(this);
    this.doneEditing = this.doneEditing.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let regionals = await pullViridianRegionals();
    this.setState({ regionals: regionals }, async () => await this.updatePage());
  }

  changePropertyName(event: React.ChangeEvent<HTMLInputElement>): void {
    let settings = { propertyName: event.target.value, address: this.state.address, portfolio: this.state.portfolio, regionalList: this.state.regionalList };
    SetSettings(this.settings, settings);
    this.setState({ propertyName: event.target.value }, this.updatePage);
  }

  changeAddress(event: React.ChangeEvent<HTMLInputElement>): void {
    let settings = { propertyName: this.state.propertyName, address: event.target.value, portfolio: this.state.portfolio, regionalList: this.state.regionalList };
    SetSettings(this.settings, settings);
    this.setState({ address: event.target.value }, this.updatePage);
  }

  changePortfolio(event: SelectOptions | null): void {
    let settings = { propertyName: this.state.propertyName, address: this.state.address, portfolio: event, regionalList: this.state.regionalList };
    SetSettings(this.settings, settings);
    this.setState({ portfolio: event }, this.updatePage);
  }

  async changeRegional(event: SelectOptions[] | null) {
    let settings = { propertyName: this.state.propertyName, address: this.state.address, portfolio: this.state.portfolio, regionalList: event };
    SetSettings(this.settings, settings);
    this.setState({ regionalList: event }, async () => this.updatePage());
  }

  selectPageCount(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({ pageCount: event }, this.updatePage);
  }

  async updatePage(): Promise<void> {
    this.setState({ headers: this.generateHeaders() });
    const name: string = this.state.propertyName === '' ? null : this.state.propertyName;
    const addr: string = this.state.address === '' ? null : this.state.address;
    const data = {
      Page: this.state.page.value,
      PageCount: this.state.pageCount.value,
      PropertyName: name,
      Address: addr,
      IsRegional: (await getUserJobFromServer() === Jobs.Regional),
      Regionals: this.state.regionalList.map(u => u.value),
      Portfolio: this.state.portfolio.value,
      UserUID: getUserID()
    }
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
    let response = await axios.post("./api/property/get-page-list/", data);
    if (response.status === 200) {
      const arr: Array<Structs.PropertyRowData> = [];
      const pages: Array<SelectOptions> = [];
      response.data.properties.forEach((index: any) => {
        arr.push({
          address: index.address,
          name: index.name,
          previousName: index.previousName,
          portfolio: RelatedPortfolios[parseInt(index.relatedPortfolio)],
          unitCount: index.unitCount,
          regionalManager: index.regionals,
          siteManager: index.siteManagers,
          maintenanceManager: index.maintenanceTechs,
          uid: index.uid
        });
      });
      console.log(response.data.count, this.state.pageCount.value);
      const count = Math.ceil(response.data.count / parseFloat(this.state.pageCount.value));
      for (let i = 0; i < count; ++i) {
        pages.push({
          label: (i + 1).toFixed(0),
          value: i.toFixed(0)
        })
      }
      this.setState({
        properties: arr,
        pages: pages
      })
    }
  }

  selectPage(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({ page: event }, this.updatePage);
  }

  personEdit(uid: string): void {
    this.personEditRef.current.display(uid, this.doneEditing);
  }

  async doneEditing(): Promise<void> {
    await this.updatePage();
  }

  generateHeaders(): Array<FilterAndSettingField> {
    let headers: Array<FilterAndSettingField> = [
      {
        columnName: "Property Name",
        setting: true,
        filter: {
          value: this.state.propertyName,
          changeFilter: this.changePropertyName,
          name: "propertyName",
          placeHolder: "Filter by Name",
          type: "text"
        },
      },
      {
        columnName: "Property Address",
        setting: true,
        filter: {
          value: this.state.address,
          changeFilter: this.changeAddress,
          name: "address",
          placeHolder: "Filter By Address",
          type: "text"
        }
      },
      {
        columnName: "Related Portfolio",
        setting: true,
        filter: {
          value: this.state.portfolio,
          changeFilter: this.changePortfolio,
          multi: false,
          options: RelatedPortfolios_FilterSelect,
          name: "portfolio"
        },
        additionalClasses: "twenty"
      },
      {
        columnName: "Units",
        setting: true,
        filter: {},
        additionalClasses: "ten"
      },
      {
        columnName: "Regional Manager",
        setting: true,
        filter: {
          value: this.state.regionalList,
          multi: true,
          options: this.state.regionals,
          name: "regional",
          changeFilter: this.changeRegional
        }
      },
      {
        columnName: "Site Manager",
        setting: true,
        filter: {}
      },
      {
        columnName: "Maintenance Manager",
        setting: true,
        filter: {}
      },
      {
        columnName: "",
        setting: true,
        filter: {},
        additionalClasses: "twenty"
      }
    ]
    return headers;
  }

  /**
   * This needs to be updated to the most useful  
   */
  render(): JSX.Element {
    return (
      <div>
        <PropertyInformationEditModal ref={this.propertyEditModal} />
        <CombinePropertiesModal ref={this.propertyCombineModal} />
        <PersonEditModal ref={this.personEditRef} />
        <div id="property-management-table-container" style={{ marginLeft: "-1vw", minHeight: "85vh", maxHeight: "85vh", maxWidth: "100%", overflowY: "scroll" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody id="list-of-properties">
              {
                this.state.properties.map((item: Structs.PropertyRowData) => (
                  <PropertyRow key={item.uid} data={item} callback={this.selectPage} personEdit={this.personEdit}
                  />
                ))
              }
            </tbody>
          </table>
        </div>
        <div style={{ marginLeft: "-1vw" }}>
          <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
          <Row>
            <Col xs='2'>
              <input type="button" className="standard-input" value="Add New Property" onClick={() => this.propertyEditModal.current.show()} />
            </Col>
            <Col xs='2'>
              {/*<input type="button" className="standard-input" value="Combine Properties" onClick={() => this.propertyCombineModal.current.show()} />*/}
            </Col>
            <Col xs='4'></Col>
            <Col xs='2'>
              <Select
                id="page-number"
                menuPlacement="top"
                value={this.state.page}
                options={this.state.pages}
                onChange={this.selectPage}
                styles={reactSelectBasicStyle}
              />
            </Col>
            <Col xs='2'>
              <Select
                menuPlacement="top"
                id="page-count"
                value={this.state.pageCount}
                options={[{ label: '25', value: '25' }, { label: '50', value: '50' }, { label: '75', value: '75' }, { label: '100', value: '100' }]}
                onChange={this.selectPageCount}
                styles={reactSelectBasicStyle}
              />
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

interface PersonEditModalProps {

}

interface PersonEditModalState {
  show: boolean;
  title: string;
  regionalManagerList: Array<SelectOptions>;
  activeRegionals: Array<SelectOptions>
  regionalManagers: Array<SelectOptions>;

  siteManagerList: Array<SelectOptions>;
  siteManagers: Array<SelectOptions>;
  activeSite: Array<SelectOptions>;

  maintenanceManagerList: Array<SelectOptions>;
  maintenanceManagers: Array<SelectOptions>;
  activeMaintenance: Array<SelectOptions>;

  propertyUID: string;
  callback: () => void;
}

class PersonEditModal extends React.Component<PersonEditModalProps, PersonEditModalState>{

  constructor(props: PersonEditModalProps) {
    super(props);
    this.state = {
      show: false,
      title: "",
      regionalManagerList: [],
      siteManagerList: [],
      maintenanceManagerList: [],
      regionalManagers: [],
      siteManagers: [],
      maintenanceManagers: [],
      propertyUID: "",
      callback: undefined,
      activeRegionals: [],
      activeSite: [],
      activeMaintenance: []
    }
    this.patchRegional = this.patchRegional.bind(this);
    this.patchSite = this.patchSite.bind(this);
    this.patchMaintenance = this.patchMaintenance.bind(this);
    this.callback = this.callback.bind(this);
  }

  async display(property: string, callback: () => void): Promise<void> {
    let regionalList = await pullViridianRegionals();
    let siteList = await pullViridianSiteManagers();
    let maintenanceList = await pullViridianMaintenanceManagers();
    let regionals = await axios.get('./api/property-employee-map/regionals/' + property, { validateStatus: () => true });
    let siteManagers = await axios.get('./api/property-employee-map/site-managers/' + property, { validateStatus: () => true });
    let maintenanceManagers = await axios.get('./api/property-employee-map/maintenance-managers/' + property, { validateStatus: () => true });
    let activeRegionalList = [...regionalList];
    let activeSiteList = [...siteList];
    let activeMaintenanceList = [...maintenanceList];
    this.setState({
      show: true,
      propertyUID: property,
      regionalManagerList: regionalList,
      activeRegionals: FilterUsedOptions(activeRegionalList, regionals.data),
      regionalManagers: regionals.data,
      siteManagerList: siteList,
      siteManagers: siteManagers.data,
      activeSite: FilterUsedOptions(activeSiteList, siteManagers.data),
      maintenanceManagerList: maintenanceList,
      maintenanceManagers: maintenanceManagers.data,
      activeMaintenance: FilterUsedOptions(activeMaintenanceList, maintenanceManagers.data),
      callback: callback
    })
  }

  callback(): void {
    this.setState({
      show: false
    });
    if (this.state.callback) {
      this.state.callback();
    }
  }

  async patchRegional(event: SelectOptions[] | null): Promise<void> {
    if (event === null) { return }
    let list: Array<any> = [];
    event.forEach((item) => {
      const data = {
        EmployeeUID: item.value
      };
      list.push(data);
    });
    var filter = [...this.state.regionalManagerList];
    var active = FilterUsedOptions(filter, event);
    let response = await axios.patch('./api/property-employee-map/patch-regional', { list: list, propertyUID: this.state.propertyUID, modifierUID: getUserID() }, { validateStatus: () => true })
    if (response.status !== 202) {
      //handle errors
    }
    this.setState({ regionalManagers: event, activeRegionals: active }, () => this.state.callback())
  }

  async patchSite(event: SelectOptions[] | null): Promise<void> {
    if (event === null) { return }
    let list: Array<any> = [];
    event.forEach((item) => {
      const data = {
        EmployeeUID: item.value
      };
      list.push(data);
    });
    var filter = [...this.state.siteManagerList];
    var active = FilterUsedOptions(filter, event);
    let response = await axios.patch('./api/property-employee-map/patch-site-managers', { list: list, propertyUID: this.state.propertyUID, modifierUID: getUserID() }, { validateStatus: () => true })
    if (response.status !== 202) {
      //handle errors
    }
    this.setState({ siteManagers: event, activeSite: active }, () => this.state.callback())
  }

  async patchMaintenance(event: SelectOptions[] | null): Promise<void> {
    if (event === null) { return }
    let list: Array<any> = [];
    event.forEach((item) => {
      const data = {
        EmployeeUID: item.value
      };
      list.push(data);
    });
    var filter = [...this.state.maintenanceManagerList];
    var active = FilterUsedOptions(filter, event);
    let response = await axios.patch('./api/property-employee-map/patch-maintenance-managers', { list: list, propertyUID: this.state.propertyUID, modifierUID: getUserID() }, { validateStatus: () => true })
    if (response.status !== 202) {
      //handle errors
    }
    this.setState({ maintenanceManagers: event, activeMaintenance: active }, () => this.state.callback())
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.show} style={{ borderRadius: "15%", backgroundColor: "#4C4A42" }} toggle={() => this.setState({ show: false })}>
        <ModalHeader tag="h3" style={{ backgroundColor: "#4C4A42" }}>
          <b style={{ color: "#d7c7a7" }}>Edit Property Managers</b>
        </ModalHeader>
        <ModalBody style={{ backgroundColor: "#4C4A42", color: "#d7c7a7" }}>
          <Row>
            <Col>Regional</Col>
            <Col>
              <Select
                value={this.state.regionalManagers}
                styles={reactSelectBasicStyle}
                options={this.state.activeRegionals}
                onChange={this.patchRegional}
                menuPlacement="auto"
                isMulti
                menuPosition="fixed"
                onFocus={RemoveStickyOverlays}
                onBlur={RestoreStickyOverlays}
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>Site</Col>
            <Col>
              <Select
                value={this.state.siteManagers}
                styles={reactSelectBasicStyle}
                options={this.state.activeSite}
                onChange={this.patchSite}
                isMulti
                menuPlacement="auto"
                menuPosition="fixed"
                onFocus={RemoveStickyOverlays}
                onBlur={RestoreStickyOverlays}
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>Maintenenace</Col>
            <Col>
              <Select
                value={this.state.maintenanceManagers}
                styles={reactSelectBasicStyle}
                options={this.state.activeMaintenance}
                onChange={this.patchMaintenance}
                isMulti
                menuPlacement="auto"
                menuPosition="fixed"
                onFocus={RemoveStickyOverlays}
                onBlur={RestoreStickyOverlays}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter style={{ backgroundColor: "#4C4A42" }}>
          <input type="button" value="Done" className="standard-input" onClick={this.callback}
            style={{
              backgroundColor: "#c2a877",
              color: "#15405c"
            }}
          />
        </ModalFooter>
      </Modal>
    )
  }
}