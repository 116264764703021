import * as React from 'react';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import TransitionPlanCategoryModal from './TransitionPlanCategoryWidget.modals';
import axios from 'axios';


export default class TransitionPlanCategoryWidget extends React.Component<{}, { categories: Array<SelectOptions>}> {

  modal: React.RefObject<TransitionPlanCategoryModal> = React.createRef<TransitionPlanCategoryModal>();

  constructor(props: {}) {
    super(props);
    this.state = {
      categories: []
    }
  }

  async componentDidMount(): Promise<void> {
    let items = await axios.get('./api/transition-plan-categories/minimal');
    this.setState({ categories: items.data });
  }

  render(): JSX.Element {
    return (
      <>
        <TransitionPlanCategoryModal ref={this.modal} />
        {RenderHeader("Transition Plan Categories")}
        <div style={{ minHeight: "25vh", maxHeight: "25vh", overflowX: "hidden", overflowY: "auto"}}>
          <table className="fixedTable">
            <thead>
              <tr>
                <th className="fixedTableHeaderFirst">Category</th>
                <th className="fixedTableHeader twenty">Edit</th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.categories.map((item) => (
                  <tr key={item.value}>
                    <td className="fixedTableCellFirst">{item.label}</td>
                    <td className="fixedTableCellFirst">
                      <input type="button" value="Edit" className="standard-input" onClick={() => this.modal.current.show(item.value)} />
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <input type="button" value="Create Category" className="standard-input" onClick={() => this.modal.current.show()} />
      </>
    )
  }
}